import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import Cookies from "js-cookie";
import deepParseJson from "utils/deepParseJson";

/*
 * Handle pre request
 * 1. Retrieve auth token from local storage and append in header if exists
 * 2. Return responseProduct BASE URL
 */
export function handlePreRequest(config) {
  const rawPersistData = Cookies.get(PERSIST_STORE_NAME);
  const token = deepParseJson(rawPersistData);

  //   const accessToken = persistData.auth.session.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

/*
 * Handle request error while sending any data to API
 */
export function handleRequestError(error) {
  return Promise.reject(error);
}

/*
 * Handle response Success
 */
export function handleResponseSuccess(response) {
  return response.data;
}

/*
 * Handle response Error
 * 1. Check for 401 and 403 response code from server and logout
 */
export function handleResponseError(error) {
  // if (
  //     error.response && [401].includes(error.response.status)
  // ) {
  //     authenticationService.localLogout()
  // }
  return Promise.reject(
    (error && error.response && error.response.data) || error
  );
}

import axios from "axios";
import appConfig from "configs/app.config";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
import {
  handlePreRequest,
  handleRequestError,
  handleResponseError,
  handleResponseSuccess,
} from "./httpInterceptors";

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API_URL,
});

BaseService.interceptors.request.use(handlePreRequest, handleRequestError);

BaseService.interceptors.response.use(handleResponseSuccess,handleResponseError);

export default BaseService;

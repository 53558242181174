import { PERSIST_STORE_NAME } from "constants/app.constant";
import BaseService from "./BaseService";
import deepParseJson from "utils/deepParseJson";
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "constants/api.constant";

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export default ApiService;
